
















import Vue from 'vue';
import Component from 'vue-class-component';
import FixitHeader from '@/components/fixit-header.vue';

@Component({
    components: { FixitHeader }
})
export default class DonePage extends Vue {

    private readonly imgBaseUrl = "https://fixit.bitforge.ch";

    get lhtImage() {
        if (this.$store.state.school) {
            return this.imgBaseUrl + this.$store.state.school.lht_image.path;
        } else {
            return '/img/done.png';
        }
    }

    public restart() {
        // This reloads the whole app, intentionally
        const slug = this.$store.state?.building?.slug
        if (slug) {
            window.location.href = slug;
        } else {
            this.$f7.dialog.alert("Bitte QR Code neu scannen.", "Pfad nicht gefunden");
        }

        this.$gtag.event('restart', {
            'event_category': 'report',
        });
    }
}
