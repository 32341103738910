









import Vue from 'vue';
import Component from 'vue-class-component';
import { SelectedType } from '@/types';

@Component
export default class ActiveType extends Vue {
    get type(): SelectedType {
      return this.$store.state.type;
    }

    public onBack() {
        this.$f7router.back();

        this.$gtag.event('changing', {
            'event_category': 'type',
            'event_label': this.type,
        });
    }
}
