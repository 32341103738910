



















import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ImgAsset extends Vue {

    private readonly emptyImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDMyIDMyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSw0LDQpIj4KICAgICAgICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0eWxlPSJmaWxsOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDQsNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0yMSw2TDE3LjgzLDZMMTYsNEwxMCw0TDEwLDZMMTUuMTIsNkwxNi45NSw4TDIxLDhMMjEsMjBMNSwyMEw1LDExTDMsMTFMMywyMEMzLDIxLjEgMy45LDIyIDUsMjJMMjEsMjJDMjIuMSwyMiAyMywyMS4xIDIzLDIwTDIzLDhDMjMsNi45IDIyLjEsNiAyMSw2Wk04LDE0QzgsMTYuNzYgMTAuMjQsMTkgMTMsMTlDMTUuNzYsMTkgMTgsMTYuNzYgMTgsMTRDMTgsMTEuMjQgMTUuNzYsOSAxMyw5QzEwLjI0LDkgOCwxMS4yNCA4LDE0Wk0xMywxMUMxNC42NSwxMSAxNiwxMi4zNSAxNiwxNEMxNiwxNS42NSAxNC42NSwxNyAxMywxN0MxMS4zNSwxNyAxMCwxNS42NSAxMCwxNEMxMCwxMi4zNSAxMS4zNSwxMSAxMywxMVpNNSw2TDgsNkw4LDRMNSw0TDUsMUwzLDFMMyw0TDAsNEwwLDZMMyw2TDMsOUw1LDlMNSw2WiIgc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvOyIvPgogICAgPC9nPgo8L3N2Zz4K";

    public imgPreview: string = this.emptyImage;
    public selectedFile: File | null | undefined = null;

    get imgTitle() {
        return this.selectedFile ? this.selectedFile.name : 'Kein Bild ausgewählt';
    }

    public async choose() {
        const input = this.$refs['file-input'] as HTMLInputElement;
        input.click();
    }

    public clearImage() {
        const input = this.$refs['file-input'] as HTMLInputElement;
        input.value = "";
        this.selectedFile = null;
        this.imgPreview = this.emptyImage;
    }

    public async changed(event: Event) {
        const input = event.target as HTMLInputElement;
        this.selectedFile = input.files?.item(0);

        if (this.selectedFile) {
            const base64img = await this.readImage(this.selectedFile);
            this.imgPreview = base64img;

            this.$gtag.event('image_added', {
                'event_category': 'report',
                'event_label': this.imgTitle,
            });
        } else {
            this.imgPreview = this.emptyImage;
        }
    }

    private readImage(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!file) reject('No file provided');
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                resolve(fileReader.result as string);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
            fileReader.readAsDataURL(file);
        });
    }
}
