




































































import Vue from 'vue';
import Component from 'vue-class-component';
import { DamageReport, Asset, CollectionLink, Linkable } from '@/types';
import ImgAsset from '@/components/img-asset.vue';
import ActiveType from '@/components/active-type.vue';
import FixitHeader from '@/components/fixit-header.vue';
import api from '@/api';

@Component({
    components: { FixitHeader, ActiveType, ImgAsset }
})
export default class ReportPage extends Vue {

    private sendDisabled = true;

    private onChanged() {
        const form = this.$refs.reportForm as HTMLFormElement;
        if (this.requiredFilled(form)) {
            const isValid = form.checkValidity();
            this.sendDisabled = !isValid;
        } else {
            this.sendDisabled = true;
        }
    }

    private async submit() {
        const progressDialog = this.$f7.dialog.preloader('Ihre Meldung wird gesendet');
        try {
            // Collect damage report data
            const form = this.$refs.reportForm as HTMLFormElement;
            const data = this.serializeForm(form) as DamageReport;
            data.school = this.createLink('schools', this.$store.state.school);
            data.building = this.createLink('buildings', this.$store.state.building);
            data.type = this.$store.state.type.name;

            // Track form submit
            this.$gtag.event('submit', {
                'event_category': 'report',
                'event_label': data.type,
            });

            // Upload images as assets
            const assets = await this.uploadAssets();
            if (assets.length > 0) {
                data.image1 = assets[0];
            }
            if (assets.length > 1) {
                data.image2 = assets[1];
            }

            // Submit damage report
            await api.submitReport(data);

            // Finishing
            progressDialog.close();
            this.$f7router.navigate('/done/');
            this.$gtag.event('submitted', {
                'event_category': 'report',
                'event_label': data.type,
            });

        } catch (err) {
            progressDialog.close();
            this.$f7.dialog.alert(err, "Fehler");
            this.$gtag.event('error', {
                'event_category': 'report',
                'event_label': err,
            });
        }
    }

    private createLink(collection: string, entry: Linkable): CollectionLink {
        return {
            _id: entry._id,
            link: collection,
            display: entry.name
        };
    }

    public async uploadAssets(): Promise<Asset[]> {
        const form = this.$refs.assetsForm as HTMLFormElement;
        const data = new FormData(form);
        const response = await api.addAssets(data);
        return response.assets;
    }

    public requiredFilled(form: HTMLFormElement) {
        const formData = new FormData(form);
        for (const key of formData.keys()) {
            if (key == 'phone') continue;
            if (!formData.get(key)) return false;
        }
        return true;
    }

    public serializeForm(form: HTMLFormElement) {
        // eslint-disable-next-line
        const obj = {} as any;
        const formData = new FormData(form);
        for (const key of formData.keys()) {
            obj[key] = formData.get(key);
        }
        return obj;
    }
}
