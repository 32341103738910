import Vue from 'vue';
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-XCPMC9N5FX" }
});

import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';

import '@/css/icons.css';
import '@/css/app.css';

import App from '@/app.vue';
import store from '@/store';

Framework7.use(Framework7Vue);

// Initialize Vue App
new Vue({
  el: '#app',
  render: h => h(App),
  store: store,
  created() {
    //Initialize building from 3 letter slug (/iga)
    const slug = window.location.pathname.replace('/', '').trim();
    if (slug.match(/[a-z]{3}/)) {
      store.dispatch('loadBuilding', slug);
    }
  },
  components: {
    app: App
  },
})


