


























































import Vue from 'vue';
import Component from 'vue-class-component';
import TypeCard from '@/components/type-card.vue';

@Component({
    components: { TypeCard }
})
export default class TypeSelection extends Vue {

}
