










import Vue from 'vue';
import Component from 'vue-class-component';
import { SelectedType } from '@/types';
import FixitHeader from '@/components/fixit-header.vue';
import TypeSelection from '@/components/type-selection.vue';
import EventBus, { Msg } from '@/eventBus'

@Component({
    components: { FixitHeader, TypeSelection }
})
export default class MessageTypePage extends Vue {

    private get building() {
        return this.$store.state.building;
    }

    public mounted() {
        EventBus.$on(Msg.TYPE_SELECTED, this.onTypeSelected);
    }

    public unmounted() {
        EventBus.$off(Msg.TYPE_SELECTED, this.onTypeSelected);
    }

    public onTypeSelected(title: SelectedType) {
        this.$store.dispatch("saveType", title);

        this.$gtag.event('selected', {
            'event_category': 'type',
            'event_label': title.name,
        });

        setTimeout(() => {
            this.$f7router.navigate('/report/');
        }, 500);
    }
}
