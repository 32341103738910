import Vue from 'vue';
import Vuex from 'vuex';
import { Building, School, SelectedType } from './types';
import api from '@/api';

Vue.use(Vuex);

/**
 * Vuex is defintive a bit overkill here.
 * However, it's nice to inspect the store state
 */
export default new Vuex.Store({

    state: {
        building: null as Building | null,
        school: null as School | null,
        type: null as SelectedType | null,
    },

    mutations: {
        setBuilding(state, building: Building) {
            state.building = building;
        },
        setSchool(state, school: School) {
            state.school = school;
        },
        setType(state, type: SelectedType) {
            state.type = type;
        }
    },

    actions: {
        async loadBuilding(context, slug: string) {
            // Search the building for the given slug (e.g. fixit.biforge.ch/iga => iga)
            const buildings = await api.getBuildings();
            const buildingForSlug = buildings.entries.find(f => f.slug === slug);

            if (buildingForSlug) {
                // Save the building for the scanned QR Code
                context.commit('setBuilding', buildingForSlug);

                var schools = await api.getSchools();
                const school = schools.entries.find(f => f._id == buildingForSlug.school._id);
                context.commit('setSchool', school)
            }
        },
        saveType(context, type: SelectedType) {
            context.commit('setType', type);
        }
    }
});