












import { Vue, Component, Watch } from 'vue-property-decorator';
import { SelectedType } from '@/types';
import FixitHeader from '@/components/fixit-header.vue';
import TypeSelection from '@/components/type-selection.vue';
import EventBus, { Msg } from '@/eventBus'

@Component({
    components: { FixitHeader, TypeSelection }
})
export default class MessageTypePage extends Vue {

    // Navigate to type selectiong as soon as building is initialized
    @Watch('$store.state.building')
    private onBuildingSelected() {
        this.$f7router.navigate('/type/', {animate: false});
        //this.$f7router.navigate('/pin/', {animate: false});
    }
}
