import { AddAssetResponse, AddAssetResponse as AddAssetsResponse, BuildingsResponse, DamageReport, SchoolsResponse} from '@/types';

 class FixitApi {
    private readonly baseUrl = "https://fixit.bitforge.ch/cockpit/api";
    private readonly token = "8e97b8812f683e81d566b6b6ca598b";

    public async getBuildings(): Promise<BuildingsResponse> {
        const response = await fetch(`${this.baseUrl}/collections/get/buildings?token=${this.token}`);
        return  response.json() as Promise<BuildingsResponse>;
    }

    public async getSchools(): Promise<SchoolsResponse> {
        const response = await fetch(`${this.baseUrl}/collections/get/schools?token=${this.token}`);
        return  response.json() as Promise<SchoolsResponse>;
    }

    public async submitReport(report: DamageReport): Promise<DamageReport> {
        const response = await fetch(`${this.baseUrl}/collections/save/damage_reports?token=${this.token}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                data: report
            })
        });
        if (!response.ok) {
            var errorMsg = await this.getError(response);
            throw Error(errorMsg);
        }
        return response.json() as Promise<DamageReport>;
    }

    public async addAssets(data: FormData): Promise<AddAssetResponse> {
        const response = await fetch(`${this.baseUrl}/cockpit/addAssets?token=${this.token}`, {
            method: 'POST',
            body: data
        });
        if (!response.ok) {
            var errorMsg = await this.getError(response);
            throw Error(errorMsg);
        }
        return response.json() as Promise<AddAssetsResponse>;
    }

    private async getError(response: Response) {
        try {
            const jsonErr = await response.json();
            return jsonErr?.error || jsonErr;
        } catch(err) {
            return response?.statusText || "HTTP Status:" + response.status;
        }
    }
}

export default new FixitApi();