






import Vue from 'vue';
import Component from 'vue-class-component';
import routes from '@/routes';
import { Router } from 'framework7/modules/router/router';

@Component
export default class App extends Vue {

    public f7params = {
        name: 'fixit',
        theme: 'md',
        routes: routes,
        view: {
            stackPages: true
        },
    }

    public mounted() {
        this.$f7.on('pageAfterIn', this.trackPageView);
    }

    public unmounted() {
        this.$f7.off('pageAfterIn', this.trackPageView);
    }

    public trackPageView(page: Router.Page) {
        this.$gtag.pageview({
          page_path: page.route.path,
          page_title: page.name
        });
    }

}
