











import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class FixitHeader extends Vue {

    get build() {
        return this.$store.state.building
    }

    get school() {
        return this.build ? this.build.school.display : "";
    }

    get building() {
        return this.build ? this.build.name : "";
    }
}
