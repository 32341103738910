




















import { Vue, Component, Watch } from 'vue-property-decorator';
import FixitHeader from '@/components/fixit-header.vue';

@Component({
    components: { FixitHeader,  }
})
export default class PinCodePage extends Vue {

    private readonly pin = "1234";
    private error = "";
    private hasError = false;

    private mounted() {
        this.$$('input[name="code"]').focus();
    }

    public validatePin(e: InputEvent) {
        const input = this.$$('input[name="code"]');
        const code = input.val() as string;
        if (code === this.pin) {
            this.$f7router.navigate('/type/');
        } else {
            if (code.length >= 4) {
                this.hasError = true;
                this.error = "Pin-Code ungültig.";
                input.val(code.substr(0, 5));
            } else {
                this.hasError = false;
                this.error = "";
            }
        }
    }
}
