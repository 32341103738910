import Vue from 'vue';

export enum Msg {
    TYPE_SELECTED = "type-selected"
}

declare class EventBus {
    $on(event: string, callback: Function): this;
    $once(event: string, callback: Function): this;
    $off(event?: string, callback?: Function): this;
    // eslint-disable-next-line
    $emit(event: string, ...args: any[]): this;
}

export default new Vue() as EventBus;