









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SelectedType } from '@/types';
import EventBus, { Msg } from '@/eventBus'

@Component
export default class TypeCard extends Vue {

    @Prop(String)
    readonly icon: string | undefined;

    @Prop(String)
    readonly title: string | undefined;

    private isActive = false;

    public mounted() {
        EventBus.$on(Msg.TYPE_SELECTED, this.onTypeSelected);
    }

    public unmounted() {
        EventBus.$off(Msg.TYPE_SELECTED, this.onTypeSelected);
    }

    public onTypeSelected(selected: SelectedType) {
        this.isActive = this.title === selected.name;
    }

    public clicked() {
        EventBus.$emit(Msg.TYPE_SELECTED, {
            name: this.title,
            icon: this.icon
        });
    }
}
