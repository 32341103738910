import HomePage from '@/pages/home.vue';
import PinCodePage from '@/pages/pincode.vue';
import TypePage from '@/pages/type.vue';
import ReportPage from '@/pages/report.vue';
import DonePage from '@/pages/done.vue';

const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/pin/',
        component: PinCodePage
    },
    {
        path: '/type/',
        component: TypePage,
        keepAlive: true
    },
    {
        path: '/report/',
        component: ReportPage,
        keepAlive: true
    },
    {
        path: '/done/',
        component: DonePage
    },
];

export default routes;